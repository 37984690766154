import axios from "axios";

const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/dhne2us3s/upload";
const CLOUDINARY_UPLOAD_PRESET = "alaarokun_unsigned_preset"; // The upload preset you created in Cloudinary

export const uploadPdfToCloudinary = async (pdfFile) => {
  const formData = new FormData();
  formData.append("file", pdfFile);
  formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);

  try {
    const response = await axios.post(CLOUDINARY_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.secure_url; // Returns the URL of the uploaded file
  } catch (error) {
    console.error("Error uploading file to Cloudinary:", error);
    throw new Error("Failed to upload file to Cloudinary");
  }
};
