// CommentSection.js
import React, { useEffect, useState } from "react";
import { db } from "../../firebase"; // Import your Firestore instance
import {
  doc,
  getDoc,
  addDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore"; // Firestore methods
import { useAuth } from "../../contexts/AuthContext"; // Assuming you have an AuthContext for user authentication

export default function CommentSection({ postId }) {
  const { currentUser } = useAuth(); // Get the current user from AuthContext
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  // Fetch comments in real-time
  useEffect(() => {
    const fetchCommentsWithUsers = async () => {
      const commentsRef = collection(db, "posts", postId, "comments");
      const q = query(commentsRef, orderBy("createdAt", "desc"));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const commentsWithUserData = await Promise.all(
          snapshot.docs.map(async (docSnap) => {
            const commentData = docSnap.data();
            const userSnapshot = await getDoc(doc(db, "users", commentData.userId));
            
            return {
              id: docSnap.id,
              ...commentData,
              fullName: userSnapshot.exists()
                ? userSnapshot.data().fullName
                : "Anonymous",
            };
          })
        );
        
        setComments(commentsWithUserData);
      });

      return () => unsubscribe();
    };

    fetchCommentsWithUsers();
  }, [postId]);

  // Add a new comment
  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim() || !currentUser) return; // Ensure user is authenticated

    try {
      const commentsRef = collection(db, "posts", postId, "comments");

      await addDoc(commentsRef, {
        content: newComment,
        createdAt: new Date(),
        userId: currentUser.uid,  
      });

      setNewComment("");
    } catch (error) {
      alert("Error Adding Comment", error);
      console.error("Error adding comment: ", error);
    }
  };

  return (
    <div>
      <h2>Comments</h2>
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id} className="comment">
            <p>
              <strong>{comment.fullName}</strong>: {comment.content}
            </p>
          </div>
        ))
      ) : (
        <p>No comments yet. Be the first to comment!</p>
      )}

      {currentUser ? (
        <form className="form1Contact" onSubmit={handleAddComment}>
          <textarea className="commentInput"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            required
          />
          <button type="submit">Post Comment</button>
        </form>
      ) : (
        <div>
          <p>Sign in to add a comment</p>
          {/* Add link to login page */}
        </div>
      )}
    </div>
  );
}
