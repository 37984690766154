import logo1 from "../img/icon1.png";
import logo2 from "../img/icon2.png";
import profile from "../img/profile.png";
import google from "../img/google.png";
import gallery1 from "../img/gallery1.png";
import avatar from "../img/avatar.png";
import plus from "../img/plus.png";
import file from "../img/file.png";
import user from "../img/user.png";
import edit from "../img/edit.png";
import deleteI from "../img/deleteI.png";
import phone from "../img/phone.png";
import mail from "../img/mail.png";
const ImagesVar = {
  logo1,
  logo2,
  profile,
  google,
  gallery1,
  avatar,
  plus,
  file,
  user,
  edit,
  deleteI,
  phone,
  mail,
};

export default ImagesVar;
