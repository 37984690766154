import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase"; // Adjust the path as necessary

const ProtectedRoute = ({ children }) => {
  const user = auth.currentUser;

  // If user is not authenticated, redirect to admin sign-in page
  if (!user) {
    return <Navigate to="/signIn" />;
  }

  return children; // Render children if authenticated
};

export default ProtectedRoute;
