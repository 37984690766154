import React, { useEffect, useState } from "react";
import { db } from "../../../firebase"; // Import Firestore database
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore"; // Firestore methods
import "../../css/dashboard.css";
import "../../css/globalStyles.css";

const AllResources = () => {
    const [resources, setResources] = useState([]);
     useEffect(() => {
        const fetchResources = async () => {
          try {
            const resourcesCollection = collection(db, "resources"); // Update "users" to match your Firestore collection name
            const resourcesSnapshot = await getDocs(resourcesCollection);
            const resourcesList = resourcesSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setResources(resourcesList);
          } catch (error) {
            console.error("Error fetching resources:", error);
          }
        };
    
        fetchResources();
      }, []);

      const handleDelete = async (resourceId) => {
        const confirmDelete = window.confirm(
          "Are you sure you want to delete this resource?"
        );
        if (confirmDelete) {
          try {
            console.log("Attempting to delete:", resourceId);
            await deleteDoc(doc(db, "resources", resourceId)); // Correct collection name here
            setResources((prevResources) => 
              prevResources.filter((resource) => resource.id !== resourceId)
            ); // Update local state
            console.log("Delete successful");
          } catch (error) {
            console.error("Error deleting resource:", error);
          }
        }
      };
      
      

  return (
    <div>
        <h2>Resources</h2>
        <table>
            <thead>
              <tr className="headCon">
                <th>Users</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody className="list">
              {resources.length > 0 ? (
                resources.map((resource) => (
                  <tr key={resource.id}>
                    <td className="blogContentD">{resource.title}</td>
                    <td className="actions">
                    <button
                      className="delete"
                      onClick={() => handleDelete(resource.id)}
                    >
                      Delete
                    </button>
                  </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No resources found</td>
                </tr>
              )}
            </tbody>
          </table>
    </div>
  )
}

export default AllResources