import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase"; // Import your Firestore instance
import { doc, getDoc } from "firebase/firestore"; // Firestore methods
import ImagesVar from "../data/ImageVar";
import "../css/blogInside.css";
import { useAuth } from "../../contexts/AuthContext"; // Assuming you have an AuthContext for user authentication
import DOMPurify from "dompurify";
import CommentSection from "./CommentSection";

export default function BlogInside() {
  const { id } = useParams(); // Get the post ID from the URL
  const [post, setPost] = useState(null); // State to hold the blog post data
  const { currentUser } = useAuth(); // Get the current user from AuthContext
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postRef = doc(db, "posts", id);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          setPost(postSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false); // Stop loading after fetching data
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <p>Loading post...</p>; // Display loading message while data is being fetched
  }

  if (!post) {
    return <p>Post not found</p>; // Handle case where post doesn't exist
  }

  return (
    <div>
      <div className="blogContain">
        <div className="blog">
          <div className="insideTop">
            <img alt="blog cover" src={post.coverImage || ImagesVar.gallery1} />
            <div className="insideHead">
              <h1>{post.title}</h1>
              <div className="insideHeadDetails">
                <div className="insideNameNpic">
                  <img alt="profile" src={ImagesVar.avatar} />
                  <p>Admin</p>
                </div>
                <div className="insideLike">
                  <div className="likeContain">
                    <i className="fa-regular fa-calendar-days"></i>
                    <p className="date">
                      {new Date(post.createdAt?.seconds * 1000).toDateString()}
                    </p>
                  </div>
                  <div className="likeContain">
                    <i className="fa-regular fa-heart"></i>
                    <p className="date1"> {post.likes || 0}</p>
                  </div>
                  <div className="likeContain">
                    <i className="fa-regular fa-comment"></i>
                    {/* <p className="date">
                      Comments {comments.length || 0}{" "}
                      
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="blogInsideMain">
            {currentUser ? (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(post.content, {
                      ADD_TAGS: ["iframe"],
                      ADD_ATTR: [
                        "allow",
                        "allowfullscreen",
                        "frameborder",
                        "scrolling",
                      ],
                    }),
                  }}
                />

                <div className="blogBodyImagesContainer">
                  {post.blogImages && post.blogImages.length > 0 ? (
                    post.blogImages.map((imageUrl, index) => (
                      <img
                        key={index}
                        alt={`blog img ${index}`}
                        src={imageUrl}
                      />
                    ))
                  ) : (
                    <p>No images to display</p>
                  )}
                </div>
              </div>
            ) : (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      post.content?.length > 100
                        ? `${post.content.substring(0, 100)}...`
                        : post.content
                    ),
                  }}
                />
                <div className="pop">
                  <h2>SIGN IN TO CONTINUE READING</h2>
                  <Link to="/login" className="primaryBtnn">
                    Sign In
                  </Link>
                </div>
              </>
            )}
          </div>

          {/* Insert CommentSection Component Here */}
          <CommentSection postId={id} />
        </div>
        <div className="side">
          <p className="featuredHead">FEATURED ARTICLES</p>
          <div className="featured">
            <Link
              to={`/blog/${post.id}`}
              key={post.id}
              className="featuredPost"
            >
              <div className="featuredNameNpic">
                <img
                  alt="profile"
                  src={ImagesVar.avatar || "/default-profile.png"}
                />
                <p>Alaarokun</p>
              </div>
              <div className="featuredPostMain">
                <h3>{post.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      post.content?.length > 100
                        ? `${post.content.substring(0, 100)}...`
                        : post.content
                    ),
                  }}
                />
              </div>
            </Link>
            <div className="ads"></div>
            <div className="footer">
              <Link className="fLink" to="#">
                ABOUT
              </Link>
              <Link className="fLink" to="/publications">
                Publications
              </Link>
              <Link className="fLink" to="/contact">
                Contact
              </Link>
              <Link className="fLink" to="#">
                Store
              </Link>
              <Link className="fLink" to="/privacy">
                Privacy
              </Link>
              <Link className="fLink" to="terms">
                Terms
              </Link>
              <p>Copyright 2024</p>
              <p>
                Made With Love By{" "}
                <Link className="special" to="#">
                  Excellence
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
