import { useState } from "react";
import { db } from "../../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { uploadPdfToCloudinary } from "./util"; // Import the Cloudinary upload function
import { useNavigate } from "react-router-dom";

function AddResource() {
  const [pdfFile, setPdfFile] = useState(null);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pdfFile) {
      try {
        let url = await uploadPdfToCloudinary(pdfFile); // Upload PDF to Cloudinary
        url = url.replace("/upload/", "/upload/fl_attachment/"); // Add download option
  
        await addDoc(collection(db, "resources"), {
          title,
          url,
          createdAt: Timestamp.now(),
        });
        alert("Resource added successfully!");
        navigate("/dashboard");
      } catch (error) {
        console.error("Error uploading PDF:", error);
      }
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <input type="file" accept="application/pdf" onChange={handleFileChange} required />
      <button type="submit">Add Resource</button>
    </form>
  );
}

export default AddResource;
