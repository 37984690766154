import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, googleProvider, db } from "../firebase"; // Import Firebase auth, Google provider, and Firestore
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
} from "firebase/auth";
import { setDoc, doc, getDoc } from "firebase/firestore"; // Firestore to save additional user info like fullName

// Create a context for Auth
const AuthContext = createContext();

// Custom hook to use AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Sign Up with Email and Password
  const signUp = async (email, password, fullName) => {
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update profile with full name
      await updateProfile(user, { displayName: fullName });

      // Save additional user info to Firestore
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        email: user.email,
        fullName: fullName,
        createdAt: new Date(),
      });

      setCurrentUser({ ...user, fullName });
    } catch (error) {
      console.error("Error signing up:", error);
      throw error; // Consider handling this more gracefully in the UI
    }
  };

  // Sign In with Email and Password
  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(userCredential.user);
    } catch (error) {
      console.error("Error logging in:", error);
      throw error; // Consider handling this more gracefully in the UI
    }
  };

  // Google Sign-In
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Check if user exists in Firestore, if not, create a new user
      const userDocRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userDocRef); // Use getDoc to retrieve the document

      if (!userSnap.exists()) {
        await setDoc(userDocRef, {
          uid: user.uid,
          email: user.email,
          fullName: user.displayName || "Unknown", // Default to "Unknown" if displayName is not available
          createdAt: new Date(),
        });
      }

      // Update currentUser with the user info (including fullName)
      setCurrentUser({
        uid: user.uid,
        email: user.email,
        fullName: user.displayName || "Unknown", // Include displayName to match the structure in Firestore
        createdAt: userSnap.exists() ? userSnap.data().createdAt : new Date(), // Handle createdAt from Firestore
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error; // Consider handling this more gracefully in the UI
    }
  };

  // Log out
  const logout = async () => {
    await signOut(auth);
    setCurrentUser(null);
  };

  // Listen for changes to the authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is signed in, update currentUser
        setCurrentUser({
          uid: user.uid,
          email: user.email,
          fullName: user.displayName || "Unknown", // Handle the display name
        });
      } else {
        // If no user is signed in, reset currentUser
        setCurrentUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  // AuthContext value
  const value = {
    currentUser,
    signUp,
    login,
    signInWithGoogle,
    logout,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
