import "./assets/css/globalStyles.css";
import "./assets/css/index.css";
import ImagesVar from "./assets/data/ImageVar";
import { useState } from "react";
import { useAuth } from "./contexts/AuthContext";
import Contact from "./assets/pages/contact";
import Home from "./home";
import Login from "./assets/pages/login";
import Publications from "./assets/pages/publications";
import BlogInside from "./assets/pages/blogInside";
import Dashboard from "./assets/pages/admin/dashboard";
import AddBlog from "./assets/pages/admin/addBlog";
import SignIn from "./assets/pages/admin/signIn";
import BeMember from "./assets/pages/beMember";
import Reg from "./assets/pages/Reg";
import ProtectedRoute from "./components/protectedRoutes";
import EditPost from "./assets/pages/admin/EditPost";
import Terms from "./assets/pages/Terms";
import Privacy from "./assets/pages/Privacy";
import AddResource from "./assets/pages/admin/addResource";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import AllResources from "./assets/pages/admin/allResources";

function App() {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const location = useLocation();
  const showNavBar = !["/dashboard", "/addBlog", "/edit/:id"].includes(location.pathname); // Add "/addBlog" to the list
  const { currentUser, logout } = useAuth(); // Get current user and logout function

  const handleLogout = async () => {
    try {
      await logout();
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      {showNavBar && (
        <nav>
          <Link to="/" className="logo">
            <img alt="logo" src={ImagesVar.logo1} />
            <p>ALAAROKUN</p>
          </Link>
          <div className="logo">
            <div className="navBtns">
            {currentUser ? (
              <button onClick={handleLogout} className="secondaryBtn">
                Sign Out
              </button>
            ) : (
              <Link to="/login" className="secondaryBtn">
                Sign In
              </Link>
            )}
              <Link
                target="_blank"
                to="https://forms.gle/xAfYuouKzB5isQXR6"
                className="primaryBtn"
              >
                Become A Member
              </Link>
            </div>
            <Link to="/signIn">
              <img className="log2" alt="logo" src={ImagesVar.logo2} />
            </Link>
          </div>
        </nav>
      )}
      {isBannerVisible && showNavBar && (
        <div className="banner">
          <i className="fa-solid fa-x hidden"></i>
          <div className="bannerText">
            <i className="fa-solid fa-star"></i>
            <p>
              Get unlimited access to the best of ALAAROKUN. Become a
              <Link to="https://forms.gle/xAfYuouKzB5isQXR6" className="member">
                {" "}
                Family Member
              </Link>
            </p>
          </div>
          <i
            id="bannerClose"
            className="fa-solid fa-x"
            onClick={() => setIsBannerVisible(false)} // Hide banner on click
          ></i>
        </div>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/blog/:id" element={<BlogInside />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/member" element={<BeMember />} />
        <Route path="/reg" element={<Reg />} />
        <Route path="addResource" element={<AddResource />} />
        <Route path="/edit/:id" element={<EditPost />} />
        <Route path="/allResource" element={<AllResources />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/addBlog" element={<AddBlog />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </>
  );
}

function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default MainApp;
