import React, { useState } from "react";
import { db } from "../../../firebase"; // Import Firestore database
import { collection, addDoc } from "firebase/firestore"; // Firestore methods
import "../../css/addBlog.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles for the editor
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AddBlog() {
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [content, setContent] = useState("");
  const [blogImages, setBlogImages] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const navigate = useNavigate();

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "snapshare-unsigned-preset");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dhne2us3s/image/upload",
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Cloudinary upload error:", error);
      throw error;
    }
  };

  const handleBlogImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = await Promise.all(
      files.map((file) => uploadToCloudinary(file))
    );
    setBlogImages(imageUrls); // Set array of image URLs
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure blogImages is not empty
    if (blogImages.length === 0) {
      alert("Please upload at least one image for the blog.");
      return;
    }

    try {
      // Upload cover image to Cloudinary if available
      const coverImageUrl = coverImage
        ? await uploadToCloudinary(coverImage)
        : "";

      await addDoc(collection(db, "posts"), {
        title,
        coverImage: coverImageUrl,
        content,
        blogImages,
        isPremium,
        createdAt: new Date(),
      });

      // Reset form fields
      setTitle("");
      setCoverImage(null);
      setContent("");
      setBlogImages([]); // Clear uploaded image URLs
      setIsPremium(false);
      alert("Blog post created successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const toolbarOptions = [
    [{ header: [1, 2, false] }],
    [{ align: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"], // Add 'video' to allow embedding YouTube links
    ["clean"],
  ];

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <h1>Add Blog Post</h1>
        <div>
          <label>Blog Title</label>
          <input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Cover Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setCoverImage(e.target.files[0])} // Handle file input
            required
          />
        </div>
        <div>
          <label>Blog Content</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={{ toolbar: toolbarOptions }}
          />
        </div>
        <div>
          <label>Blog Images (Max 2mb):</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleBlogImagesChange} // Handle file input
            required
            multiple
          />
        </div>
        <div>
          <label>Blog Type</label>
          <div>
            <label>
              <input
                type="radio"
                value="free"
                checked={!isPremium}
                onChange={() => setIsPremium(false)}
              />
              Free
            </label>
            <label>
              <input
                type="radio"
                value="premium"
                checked={isPremium}
                onChange={() => setIsPremium(true)}
              />
              Premium
            </label>
          </div>
        </div>
        <button type="submit">Add Blog</button>
      </form>
    </div>
  );
}

export default AddBlog;
