import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed
import "../css/login.css";
import { Link, useNavigate } from 'react-router-dom';
import ImagesVar from "../data/ImageVar";

function Reg() {
  const { signUp, signInWithGoogle } = useAuth(); // Changed 'login' to 'signUp' for new user registration
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); 
  const navigate = useNavigate();

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    setError(""); 

    try {
      await signUp(email, password, fullName); // Call signUp function with full name
      navigate('/'); // Redirect to homepage or dashboard after successful signup
      console.log("Signed up successfully");
    } catch (err) {
      setError("Failed to create an account. Please try again.");
      console.error("Error signing up:", err);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/'); // Redirect to homepage or dashboard after Google sign-in
      console.log("Google logged in successfully");
      navigate('/');
    } catch (err) {
      setError("Failed to sign in with Google.");
      console.error("Error signing in with Google:", err);
    }
  };

  return (
    <div>
      <div className="bodLogin">
        <h1>Sign Up</h1>
        <p className='bodyLoginP'>
          By continuing, you agree to our <Link to="/terms">Terms</Link> and acknowledge
          that you understand the <Link to="/privacy">Privacy Policy.</Link>
        </p>
        <div className="logContain">
          <div className="google" onClick={handleGoogleSignIn}>
            <img alt="google" src={ImagesVar.google} />
            <p>Google Sign Up</p>
          </div>
          <h3>OR</h3>
          <form className='formLogin' onSubmit={handleEmailSignUp}>
            {error && <p className="error-message">{error}</p>}
            <div className="input">
              <label>Full Name *</label>
              <input
                type="text"
                required
                placeholder="John Doe"
                className="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="input">
              <label>Email *</label>
              <input
                type="email"
                required
                placeholder="youremail@email.com"
                className="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input">
              <label>Password *</label>
              <input
                required
                type="password"
                placeholder="password"
                className="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit">Sign Up</button>
            <p className='dontHave'>Have an account? <Link to="/login">Login</Link></p>
          </form>
          <div className="fotter2">
            <Link className="fLink" to="#">
              ABOUT
            </Link>
            <Link className="fLink" to="/publications">
                Publications
              </Link>
              <Link className="fLink" to="/contact">
                Contact
              </Link>
              <Link className="fLink" to="#">
                Store
              </Link>
              <Link className="fLink" to="/privacy">
                Privacy
              </Link>
              <Link className="fLink" to="terms">
                Terms
              </Link>
            <p>Copyright 2024</p>
            <p>
              Made With Love By{" "}
              <Link className="special" to="#">
                Excellence
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reg;
