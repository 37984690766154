import { auth, db } from "../firebase";  // Ensure db is imported for Firestore
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";

// Helper function to create user document in Firestore
async function createUserDocument(user, role = "free") {
  const userRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userRef);

  // Only create a new document if it doesn't already exist
  if (!userDoc.exists()) {
    await setDoc(userRef, {
      fullName: user.displayName || "Unknown",
      email: user.email,
      role,  // Assign role, default is "free"
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }
}
// Admin Login
export const adminLogin = async (email, password) => {
    const adminEmail = "admin@alaarokun.org"; // Replace with the actual admin email
    const adminPassword = "adminpassword@alaarokun.org"; // Replace with the actual admin password
  
    if (email === adminEmail && password === adminPassword) {
      return signInWithEmailAndPassword(auth, email, password);
    } else {
      throw new Error("Unauthorized: Only the admin can log in.");
    }
  };
// Register user with email and password
export const registerUser = async (email, password, fullName) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;

  // Create user document with default "free" role
  await createUserDocument(user, "free");
  return user;
};

// Login user with email and password
export const loginUser = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

// Logout user
export const logoutUser = async () => {
  return signOut(auth);
};

// Google Sign-In for users
export const signInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();
  const userCredential = await signInWithPopup(auth, googleProvider);
  const user = userCredential.user;

  // Create user document if it doesn't already exist
  await createUserDocument(user);
  return user;
};
