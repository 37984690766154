import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext"; // Adjust the path as needed
import "../css/login.css";
import ImagesVar from "../data/ImageVar";
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const { login, signInWithGoogle } = useAuth(); // Destructure login and Google sign-in functions
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State to hold error messages
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      await login(email, password);
      navigate('/'); // Redirect to homepage or dashboard after successful signup
      // Redirect or handle successful login (you can use react-router here)
      console.log("Logged in successfully");
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
      console.error("Error signing in:", err);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      // Redirect or handle successful login
      console.log("Google logged in successfully");
    } catch (err) {
      setError("Failed to log in with Google.");
      console.error("Error signing in with Google:", err);
    }
  };

  return (
    <div>
      <div className="bodLogin">
        <h1>Sign In</h1>
        <p className='bodyLoginP'>
          By continuing, you agree to our <Link to="#">Terms</Link> and acknowledge
          that you understand the <Link to="#">Privacy Policy.</Link>
        </p>
        <div className="logContain">
          <div className="google" onClick={handleGoogleSignIn}>
            <img alt="google" src={ImagesVar.google} />
            <p>Google Sign in</p>
          </div>
          <h3>OR</h3>
          <form className='formLogin' onSubmit={handleEmailLogin}>
            {error && <p className="error-message">{error}</p>} {/* Display error messages */}
            <div className="input">
              <label>Email *</label>
              <input
                type="email"
                required
                placeholder="youremail@email.com"
                className="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state
              />
            </div>
            <div className="input">
              <label>Password *</label>
              <input
                required
                type="password"
                placeholder="password"
                className="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
              />
            </div>
            <Link to="#">Forgot Password?</Link>
            <button type="submit">Sign in</button>
            <p className='dontHave'>Dont have an account? <Link to="/reg">Register</Link></p>
          </form>
          <div className="fotter2">
            <Link className="fLink" to="#">
              ABOUT
            </Link>
            <Link className="fLink" to="/publications">
                Publications
              </Link>
              <Link className="fLink" to="/contact">
                Contact
              </Link>
              <Link className="fLink" to="#">
                Store
              </Link>
              <Link className="fLink" to="/privacy">
                Privacy
              </Link>
              <Link className="fLink" to="terms">
                Terms
              </Link>
            <p>Copyright 2024</p>
            <p>
              Made With Love By{" "}
              <Link className="special" to="#">
                Excellence
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
