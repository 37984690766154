import React, { useState } from "react";
import "../css/globalStyles.css";
import "../css/contact.css";
import ImagesVar from "../data/ImageVar";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";


function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_p9dzx2c", // Replace with your EmailJS Service ID
        "template_18rgisu", // Replace with your EmailJS Template ID
        formData,
        "TCCzTGZVMNLAsq2sP" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          console.log("Message sent successfully!", response.status, response.text);
          alert("Your message has been sent!");
        },
        (error) => {
          console.error("Failed to send the message:", error);
          alert("An error occurred. Please try again.");
        }
      );

    setFormData({ fullName: "", email: "", message: "" });
  };

  return (
    <div>
      <div className="bod">
        <h1>contact us</h1>
        <p>
          Have an inquiry? Feel free to reach out to us via the form below. Our
          team will respond within 24 - 48 hours. Alternatively, you can also
          reach out to us via our social channels displayed on this page.
        </p>
        <div className="logContain">
          <div className="formContain">
          <form onSubmit={handleSubmit} className="form1Contact">
      <div className="row">
        <div className="input">
          <label>Full Name *</label>
          <input
            type="text"
            name="fullName"
            required
            placeholder="Your Fullname"
            className="text"
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>
        <div className="input">
          <label>Email *</label>
          <input
            type="email"
            name="email"
            required
            placeholder="youremail@email.com"
            className="text"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="input">
        <label>How can we help *</label>
        <textarea
          name="message"
          required
          className="text"
          placeholder="Write here..."
          value={formData.message}
          onChange={handleChange}
        ></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
            <div className="rightRow">
              <h1>OR</h1>
              <p>Get in touch via our Phone numbers, Email or Socials</p>
              <div className="contactContain">
                <div className="contactDeets">
                  <img alt="phone" src={ImagesVar.phone} />
                  <p>+234 (0) 803 801 7944</p>
                  <p>+234 (0) 909 488 0000</p>
                </div>
                <div className="contactDeets">
                  <img alt="mail" src={ImagesVar.mail} />
                  <Link to="mailto:enquiries@alaarokun.org">enquiries@alaarokun.org</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="fotter2">
          <Link className="fLink" to="#">
                ABOUT
              </Link>
              <Link className="fLink" to="/publications">
                Publications
              </Link>
              <Link className="fLink" to="/contact">
                Contact
              </Link>
              <Link className="fLink" to="#">
                Store
              </Link>
              <Link className="fLink" to="/privacy">
                Privacy
              </Link>
              <Link className="fLink" to="terms">
                Terms
              </Link>
              <p>Copyright 2024</p>
              <p>
                Made With Love By{" "}
                <Link className="special" to="#">
                  Excellence
                </Link>
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
