import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImagesVar from "../../data/ImageVar";
import { db } from "../../../firebase"; // Import Firestore database
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore"; // Firestore methods
import "../../css/dashboard.css";
import "../../css/globalStyles.css";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase";

function Dashboard() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const [totalUsers, setTotalUsers] = useState(0); // State to hold total users
  const [users, setUsers] = useState([]);

  // Fetch blogs from Firestore
  const fetchBlogs = async () => {
    const blogsCollection = collection(db, "posts");
    const blogSnapshot = await getDocs(blogsCollection);
    const blogList = blogSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setBlogs(blogList);
  };

  useEffect(() => {
    fetchBlogs(); // Fetch blogs when component mounts
  }, []);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const usersCollection = collection(db, "users"); // Adjust if your users collection has a different name
        const usersSnapshot = await getDocs(usersCollection);
        setTotalUsers(usersSnapshot.docs.length); // Set the total users count
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchTotalUsers();
  }, []);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(db, "posts");
        const postsSnapshot = await getDocs(postsCollection);
        const postsList = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsList);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const handleEdit = (postId) => {
    navigate(`/edit/${postId}`); // Navigate to the edit page for the post
  };

  const handleDelete = async (postId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "posts", postId)); // Delete the post from Firestore
        setPosts(posts.filter((post) => post.id !== postId)); // Update local state to remove the deleted post
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the admin
      console.log("Successfully logged out");
      navigate("/signIn");
      // Optionally redirect to the login page or home page
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "users"); // Update "users" to match your Firestore collection name
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <nav>
        <Link to="/" className="logo">
          <img alt="logo" src={ImagesVar.logo1} />
          <p>ALAAROKUN</p>
        </Link>
        <div onClick={handleLogout} className="logo">
          <img className="log2" alt="logo" src={ImagesVar.avatar} />
          <p>Logout</p>
        </div>
      </nav>
      <div className="body">
        <div>
          <h1>Hello Administrator!</h1>
        </div>
        <div className="cardContain">
          <div className="card">
            <img alt="add blog" src={ImagesVar.file} />
            <h1>Total Blogs: {blogs.length}</h1> {/* Display total blogs */}
          </div>
          <div className="card">
            <img alt="add blog" src={ImagesVar.user} />
            <h1>Total Users: {totalUsers}</h1>
          </div>
          <div className="cardAdd">
            <Link to="/allResource">
              <img alt="view resources" src={ImagesVar.file} />
            </Link>
            <h1>Resources</h1>
          </div>
          <div className="cardAdd">
            <Link to="/addBlog">
              <img alt="add blog" src={ImagesVar.plus} />
            </Link>
            <h1>Add Blog</h1>
          </div>
          <div className="cardAdd">
            <Link to="/addResource">
              <img alt="add resource" src={ImagesVar.plus} />
            </Link>
            <h1>Add Resource</h1>
          </div>
        </div>
        <div className="blogListCon">
          <table>
            <thead>
              <tr className="headCon">
                <th>Blog Title</th>
                <th className="actionHead">Actions</th>
              </tr>
            </thead>
            <tbody className="list">
              {posts.map((post) => (
                <tr key={post.id}>
                  <td className="blogContentD">{post.title}</td>
                  <td className="actions">
                    <button
                      className="edit"
                      onClick={() => handleEdit(post.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="delete"
                      onClick={() => handleDelete(post.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table>
            <thead>
              <tr className="headCon">
                <th>Users</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody className="list">
              {users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td className="blogContentD">{user.fullName}</td>
                    <td className="blogContentD">{user.email}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No users found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
